import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import VisionLogo from '../assets/VisionLogo.png';
import Spline from '@splinetool/react-spline';
import { signInWithRedirect, signOut, getCurrentUser } from "aws-amplify/auth";
import awsExports from "../aws-exports";


function LandingPage() {
    const navigate = useNavigate();

    const handeLogIn = async () => {
        const signInRedirectFromWindow = String(window.location.origin + window.location.pathname)
        const signInRedirectFromConfig = awsExports.oauth.redirectSignIn

        console.log({ signInRedirectFromWindow, signInRedirectFromConfig })
        let user = null;
        try {
            user = await getCurrentUser();
        } catch (error) {
            console.log(error);
            user = null
        }
        if (user) {
            navigate('/home');
        } else {
            
        }
        signInWithRedirect();
    }

    return (
        <div style={styles.container}>
            <img src={VisionLogo} alt="Vision Logo" style={styles.logoImage} />
            <main style={styles.heroImage}>
            <Spline scene="https://prod.spline.design/Cij-EshYA3si6ji6/scene.splinecode" />
            </main>
            <div className='absolute bottom-10 flex gap-x-4 flex-row w-full items-center justify-center'>
                <button onClick={() => navigate('/demo')} className="btn btn-primary">Try The Demo</button>
                {/* <button onClick={() => navigate('/home')} className="btn btn-secondary">Login</button> */}
                <button onClick={() => handeLogIn()} className="btn btn-secondary">Login</button>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        fontFamily: "Raleway, sans-serif",
    },
    heroImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: '-1',
    },
    logoImage: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        width: '100px',
        height: '100px',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '40vh',
        width: '40%',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '5px',
        marginLeft: '10%',
        marginTop: '15%',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
    },
    hook: {
        fontSize: '3em',
        fontWeight: 'bold',
    },
    description: {
        fontSize: '1em',
        color: '#C2C2C2',
        fontWeight: 'semi-bold',
    },
    callToAction: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 'auto',
    },
    tryNowButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '50px',
        backgroundColor: '#E87900',
        color: 'white',
        borderRadius: '10px',
        cursor: 'pointer',
        boxShadow: '0px -4px 20.9px 2px rgba(255, 255, 255, 0.25), 0px -4px 8px 0px rgba(30, 30, 30, 0.27) inset',
    },
    tryNowText: {
        fontSize: '1.3em',        
        fontWeight: "bold",
    },
    additionalInfo: {
        paddingTop: '10px',
        fontSize: '0.8em',
        color: '#C2C2C2',
    }
};

export default LandingPage;
