import React, { useState } from 'react';
import config from '../../config';

function Square({ isBlack, position, setClickedSquare, boardSize, piece, highlight=false, amICorrect }) {
    const [correct, setCorrect] = useState(null);

    const handleClick = () => {
        setClickedSquare(position);
        amICorrect(position);
    };

    return (
        <div
            style={styles.square(isBlack, correct, boardSize, highlight)}
            onMouseDown={handleClick}
        >
            {piece && <img src={piece} style={{width: '100%', height: '100%'}}/>}
        </div>
    );
}

const styles = {
    square: (isBlack, correct, boardSize, highlight) => {
        const size = `${100 / boardSize}%`;
        return {
            width: size,
            height: size,
            backgroundColor: highlight ? 'rgba(20, 255, 20, 0.5)' : isBlack ? '#E87900' : 'white',
            boxShadow: correct === null ? 'none' : correct ? '0px 0px 0px 5px #3ac24a inset' : '0px 0px 0px 5px #ff4747 inset',
            zIndex: correct === null ? 'auto' : 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
    },
};

export default Square;
