import React, { useEffect, useState } from 'react';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

import { fetchUser }  from '../api/profile/fetchUser';
import { fetchUserStats } from '../api/profile/fetchUserStats';
import { fetchVisionLeaderboard } from '../api/fetchVisionLeaderboard';

import SideBar from '../components/SideBar';
import Vision from '../components/Vision';
import ColorGuesser from '../components/ColorGuesser';
import Profile from '../components/Profile';
import Leaderboard from '../components/Leaderboard';
import BlindfoldPuzzles from '../components/BlindfoldPuzzles';
import OpeningPuzzles from '../components/OpeningPuzzles';
import 'aws-amplify/auth/enable-oauth-listener';




function Home({ signOut, user }) {
    const [activePage, setActivePage] = useState('vision');
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const [userData, setUserData] = useState(null);
    const [userStats, setUserStats] = useState(null);
    const [visionLeaderboard, setVisionLeaderboard] = useState(null);

    useEffect(() => {
        console.log("Fetching USERS DATA")
        fetchUser(user.userId, setUserData)
        fetchVisionLeaderboard(setVisionLeaderboard)
        fetchUserStats(user.userId, setUserStats)
    }, [refreshTrigger])


    const RenderMainContent = ({activePage, userData}) => {
        switch(activePage) {
            case 'vision':
                return (
                        <Vision userData={userData}/>
                    
                );
            case 'color guesser':
                return <ColorGuesser userData={userData} />;
            case 'blindfold puzzles':
                return <OpeningPuzzles userData={userData}/>;
            case 'leaderboard':
                return <Leaderboard visionLeaderboard={visionLeaderboard}/>;
            case 'profile':
                return <Profile userData={userData} userStats={userStats} setRefreshTrigger={setRefreshTrigger} />
            default:
                return <></>
                
        }};


    return (
        <Authenticator>
            <div style={{width: '100%', height: '100%'}}>
                <div className='absolute left-0 top-0 z-10'>
                    <SideBar activePage={activePage} setActivePage={setActivePage}/>
                </div>
                <div className='absolute top-0 h-full overflow-x-hidden' style={{width: '85vw', left: '12.5vw'}}>
                    <RenderMainContent userData={userData} activePage={activePage}/>
                </div>
                {activePage != "profile"  &&
                    <div onClick={() => setActivePage('profile')} className='absolute cursor-pointer' style={{right: '1vw', top: '1vh'}}>
                        <div className="avatar ">
                            <div className="rounded-full border-solid border-4" style={{width: '6vw', maxWidth: 90, minWidth: 60 }}>
                                    {userData && <img src={userData.picture} />}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Authenticator>
    )
}

const styles = {
    
}

export default withAuthenticator(Home);