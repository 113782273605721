import { OPENING_PUZZLE_HANDLER } from "../Endpoints";

export const markCompleted = async(userId, puzzleId) => {
    try {
        const response = await fetch(OPENING_PUZZLE_HANDLER, {
            method: "POST",
            body: JSON.stringify({
                queryType: "completed",
                userId: userId,
                puzzleId: puzzleId
            })
        })

        if (!response.ok) {
            throw new Error("Network response was not ok.");
        } else {
            return "Success"
        }

    } catch (error) {
        console.log(error)
    }
}