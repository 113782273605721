import React, { useState, useEffect, useMemo } from "react";
import useSound from 'use-sound';
import Chessboard from "../../components/chessboard/Chessboard";
import correctSound from '../../assets/sounds/correct.wav';
import wrongSound from '../../assets/sounds/wrong.wav';
import VisionLogo from '../../assets/VisionLogo.png';
import { useSpring, animated, easings } from '@react-spring/web';
import { useNavigate } from "react-router-dom";




export default function Demo4() {
    const navigate = useNavigate();
    const gameLength = 60;
    const scoreGoal = 20;
    const accuracyGoal = 0.8;

    const [playCorrect] = useSound(correctSound, { volume: 0.1 });
    const [playWrong] = useSound(wrongSound, { volume: 0.1 });
    const [timeLeft, setTimeLeft] = useState(null);

    const [clickedSquare, setClickedSquare] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [reachScore, setReachScore] = useState(false);

    const [randomSquare, setRandomSquare] = useState(null);
    const [beginGameCheck, setBeginGameCheck] = useState(false);
    const [score, setScore] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);

    const endGame = () => {
        setIsGameStarted(false);
        setRandomSquare(null);
        console.log("Game Over!")
        console.log("Score: ", score)
        console.log("Accuracy: ", accuracy)
        if (score+1 >= scoreGoal && accuracy >= accuracyGoal) {
            console.log("Phase Four Completed!");
            setShowToast(true);
        }
    }

    useEffect(() => {
      // Handles logic for the timer
      let timer;
      if (isGameStarted && timeLeft > 0) {
          timer = setTimeout(() => {
              setTimeLeft(timeLeft - 1);
          }, 1000);
      } else if (timeLeft === 0) {
          if (isGameStarted){
              endGame();
          }
          
      }
      return () => clearTimeout(timer);
  }, [timeLeft]);


    const generateRandomSquare = () => {
        // Function for generating the next random square
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
        const randomLetter = letters[Math.floor(Math.random() * letters.length)];
        const randomNumber = Math.floor(Math.random() * 8) + 1;
        console.log("Random Square: ", `${randomLetter}${randomNumber}`)
        if (`${randomLetter}${randomNumber}` === randomSquare) {
            return generateRandomSquare();
        }
        return `${randomLetter}${randomNumber}`;
    }

    const amICorrect = ({position}) => {
        // Function passed down to the Square so it can know if it was the right one on click
        if (isGameStarted) {
            return position === randomSquare;
        } else {
            return null;
        }
    }

    useEffect(() => {
        // Handles updating the accuracy
        if (correctCount + incorrectCount > 0) {
            setAccuracy(correctCount / (correctCount + incorrectCount));
        }
    }, [correctCount, incorrectCount]);

    useEffect(() => {
        if (isGameStarted) {
            if (clickedSquare === randomSquare) {
                playCorrect();
                setRandomSquare(generateRandomSquare());
                if (score+1 >= scoreGoal) {
                    setReachScore(true);
                    endGame();
                }
                setScore(score + 1);
                setCorrectCount(correctCount + 1);
            } else {
                playWrong();
                setIncorrectCount(incorrectCount + 1);
            }
        }
    }, [clickedSquare]);

    const navigateHome = () => {
        window.location.href = "/home";
    }

    const handleBeginGame = () => {
      setBeginGameCheck(true);  
      setIsGameStarted(true);
      setScore(0);
      setAccuracy(0);
      setRandomSquare(generateRandomSquare());
      setTimeLeft(gameLength);
    }

    useEffect(() => {
        if (reachScore && beginGameCheck) {
        }
    }, [reachScore]);
    
    const TimeLine = () => {
        return (
            <ul className="timeline timeline-vertical">
            <li>
                <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-success"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                </div>
                <div className="timeline-end timeline-box text-success">Start the Demo</div>
                <hr/>
            </li>
            <li>
                <hr/>
                <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-success"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                </div>
                <div className="timeline-end timeline-box text-success">Four Squares</div>
                <hr/>
            </li>
            <li>
                <hr/>
                <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-success"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                </div>
                <div className="timeline-end timeline-box text-success">First Quadrant</div>
                <hr/>
            </li>
            <li>
                <hr/>
                <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-success"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                </div>
                <div className="timeline-end timeline-box text-success">Six by Six</div>
                <hr/>
            </li>
            <li>
                <hr/>
                <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                </div>
                <div className="timeline-end timeline-box">Final Challenge</div>
            </li>
            </ul>
        )
      }

    const InstructionPanel = () => {
        return (
            <div className="artboard phone-2 absolute flex flex-col items-center right-20 bg-slate-800 shadow-black shadow-lg">
            {isGameStarted?
            <div className="absolute pl-8 top-8">
                <div className="radial-progress text-success" style={{ "--value": `${timeLeft*(100/gameLength)}`, "--size": "7rem", "--thickness": "1rem" }} role="progressbar">{timeLeft}s</div>
              </div>
              :
              <>
                <h1 className="pt-10 font-bold text-primary text-4xl">The Final Test</h1>
                <h2 className="font-semibold text-center p-4 text-error">Phase 4</h2>
              </>}

            <div className="pt-40 form-control w-8/12">
                <div className="flex flex-row w-full justify-start items-center">
                    <input
                    type="checkbox" 
                    id="beginGameCheckbox" 
                    className="checkbox checkbox-success"
                    checked={beginGameCheck}
                    readOnly
                    />
                    <p className="text-white text-center p-4">Begin the game</p>
                </div>

                <div className="flex flex-row justify-start items-center">
                    <input 
                            type="checkbox" 
                            id="reachScoreCheckbox" 
                            className="checkbox checkbox-success" 
                            checked={reachScore}
                            readOnly
                        />
                    <p className="text-white text-center p-4">{score} / {scoreGoal} in {gameLength} seconds</p>
                </div>
                <div className="flex flex-row justify-start items-center">
                    <input 
                            type="checkbox" 
                            id="reachScoreCheckbox" 
                            className="checkbox checkbox-success" 
                            checked={reachScore}
                            readOnly
                        />
                    <p className="text-white text-center p-4">{Math.round(accuracy*100)} / {accuracyGoal*100}% Accuracy</p>
                </div>
            </div>
        </div>
        )
    }
    const Toast = ({ show, message }) => {
        const props = useSpring({
          from: { opacity: 0, transform: 'translateY(20px)' },
          to: { opacity: show ? 1 : 0, transform: show ? 'translateY(0)' : 'translateY(20px)' },
          config: { duration: 500 },
        });
      
        return (
          <animated.div style={{ ...styles.successToast, ...props }} role="alert" className="alert alert-success">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{message}</span>
          </animated.div>
        );
      };


    return (
        <div className="flex flex-col justify-center items-center h-screen w-screen overflow-hidden">
            <img src={VisionLogo} alt="Vision Logo" style={styles.visionLogo} onClick={navigateHome} className="w-32 h-32"/>
            {showToast ?
                <button onClick={navigateHome} className="absolute right-10 w-2/12 btn btn-outline btn-secondary">Complete The Demo!</button>
                :
                <InstructionPanel />}
            <div style={styles.chessboardHolder}>
                {randomSquare && <h3 style={styles.randomSquare}>{randomSquare}</h3>}
                <Chessboard 
                setClickedSquare={setClickedSquare}
                amICorrect={amICorrect}
                boardSize={8}
                />
            </div>
            {!isGameStarted && !reachScore && <div className="pt-12"><button className="btn btn-warning" onClick={handleBeginGame}>Begin Game</button></div>}
            <div className="absolute -left-32">
                <TimeLine/>
            </div>
            <Toast show={showToast} message="Phase Three Completed!" />
            <button onClick={() => navigate('/home')} className="absolute bottom-5 left-5 btn btn-secondary">Skip Demo</button>
        </div>
    )
}

const styles = {
    visionLogo: {
        position: 'absolute',
        top: '0',
        left: '0',
        cursor: 'pointer'
    },
    chessboardHolder: {
        position: 'relative', // Ensure the chessboard holder is relative
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    randomSquare: {
        position: 'absolute', // Absolute to position relative to the chessboard holder
        top: '45%', // Center vertically
        left: '53%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Adjust for centering
        color: 'black',
        fontSize: '3em',
        opacity: 0.8,
        textAlign: 'center',
        pointerEvents: 'none',
        zIndex: 10,
    },
    successToast: {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        width: '20%',
        padding: '1rem',
        zIndex: '100',
        textAlign: 'center',
      }
}
