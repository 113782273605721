export default function parseFEN(fen) {
    if (!fen) {
        return null;
    }
    const rows = fen.split(' ')[0].split('/');
    const board = [];

    for (let row = 0; row < rows.length; row++) {
        const rowArray = [];
        for (let char of rows[row]) {
            if (!isNaN(char)) {
                for (let i = 0; i < parseInt(char, 10); i++) {
                    rowArray.push(null);
                }
            } else {
                rowArray.push(char);
            }
        }
        board.push(rowArray);
    }

    return board;
}
