import { useCallback, useEffect, useState } from "react";
import Chessboard from "../components/chessboard/Chessboard";
import useSound from 'use-sound';
import correctSound from '../assets/sounds/correct.wav';
import wrongSound from '../assets/sounds/wrong.wav';
import pieceMove from '../assets/sounds/pieceMove.mp3';
import { Chess } from "chess.js";
import { ArrowBackOutline } from 'react-ionicons'

function OpeningPuzzle({puzzle, puzzleCompleted, setPuzzleSelectionMode}) {
    const [clickedSquare, setClickedSquare] = useState(null);
    const [elo, setElo] = useState(1200);
    const [move, setMove] = useState("");
    const [squaresToHighlight, setSquaresToHighlight] = useState([]);

    const [playCorrect] = useSound(correctSound, { volume: 0.1 });
    const [playWrong] = useSound(wrongSound, { volume: 0.1 });
    const [playMove] = useSound(pieceMove, { volume: 0.25 });

    const baseFEN = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
    const [fen, setFen] = useState(baseFEN);
    const [isAnimating, setIsAnimating] = useState(false);
    const [playedOnce, setPlayedOnce] = useState(false);

    const amICorrect = (square) => {
        if (square) {
            if (squaresToHighlight.includes(square)) {
                setSquaresToHighlight([]);
            } else {
                if (squaresToHighlight.length > 0) {
                    checkMove(`${squaresToHighlight[0]}${square}`);
                    setSquaresToHighlight([]);
                } else {
                    setSquaresToHighlight([square]);
                }
            }
        }
    }

    
    const beginAnimation = (PGN) => {
        setPlayedOnce(true);
        setIsAnimating(true);
        const chess = new Chess(baseFEN);
        setFen(baseFEN);
        let moveIndex = 0;
        const moves = PGN.split(" ")
    
        const interval = setInterval(() => {
            if (moveIndex < moves.length) {
                playMove();
                chess.move(moves[moveIndex]);
                const updatedFEN = chess.fen();
                setFen(updatedFEN);
                moveIndex++;
            } else {
                clearInterval(interval);
                setIsAnimating(false);
            }
        }, 1500);
      };

    const checkMove = (move) => {
        if (move === puzzle.Solution) {
            playCorrect();
            setMove("");
            puzzleCompleted(puzzle.id);
        } else {
            playWrong();
            setMove("");
        }
    }

    const ControlPanel = useCallback(() => {
        if (!puzzle) return (
            <div style={styles.controlPanel}>

            </div>
        )
        return (
            <div style={styles.controlPanel}>
                 <h1 className="mt-10 self-center text-center" style={{color: "white", fontSize: "2vw", fontWeight: '600'}}>
                    {puzzle.Opening}
                </h1>
                <h2 className="mt-10" style={{color: "white", fontSize: "1.25vw", fontWeight: '600'}}>
                    {`Rating: ` + puzzle.Rating}
                </h2>

                {/* Instructions */}
                <div className="mt-10">
                    
                    <p className="mt-auto mx-4 text-center text-gray-400" style={{fontSize: "1vw"}}>
                    Watch the animation of the opening moves, then find the best move in the resulting position.
                </p>
                    
                </div>

                <div className="mt-auto w-full flex items-center flex-col">
                    <p style={{fontSize: "0.85vw"}} className="text-center w-5/6 text-gray-500">Click on a square to select it. Click on another square to make a move.</p>
                    {!isAnimating && <button
                        onClick={() => beginAnimation(puzzle.PGN)}
                        className="btn btn-lg btn-secondary justify-self-center w-full mt-10">
                            {playedOnce ? "Watch Again" : "Begin Animation"}
                        </button>}
                </div>
                
            </div>
        )
    }, [puzzle, isAnimating, playMove]);
      
      

    return (
        <div style={styles.container}>
            <ArrowBackOutline
            onClick={() => setPuzzleSelectionMode(true)}
            className="absolute top-4 left-0 cursor-pointer border-2 border-gray rounded-full"
            color={'#00000'}
            height="40px"
            width="40px"
            style={{left: 10}}
            />
            <div style={styles.chessboardHolder}>
                {fen &&
                <Chessboard
                setClickedSquare={setClickedSquare}
                amICorrect={amICorrect}
                boardSize={8}
                displaysPieces={true}
                displaysChecker={true}
                fen={fen}
                squaresToHighlight={squaresToHighlight}
                />
                }
            </div>
            <ControlPanel />
        </div>


    );
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        fontFamily: "Raleway, sans-serif",
        height: '100%',
        width: '100%',
    },
    chessboardHolder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    controlPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '5px',
        height: '80vh',
        width: '20vw',
        backgroundColor: '#242424',
        padding: '20px',
    },
}

export default OpeningPuzzle;