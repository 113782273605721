import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import Demo from './pages/Demo/Demo';
import Demo2 from './pages/Demo/Demo2';
import Demo3 from './pages/Demo/Demo3';
import Demo4 from './pages/Demo/Demo4';
import Home from './pages/Home';
import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/demo2" element={<Demo2 />} />
          <Route path="/demo3" element={<Demo3 />} />
          <Route path="/demo4" element={<Demo4  />} />
        </Routes>
        <SpeedInsights />
      </Router>
  );
}

export default App;
