import { FETCH_USER } from "../Endpoints";

export const fetchUser = async(id, setUserData) => {
    console.log("[API Request] - fetchUser")
    try {
        const response = await fetch(FETCH_USER, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
            }),
        });
        const data = await response.json();
        setUserData(data['body'])
    } catch (error) {
        console.log("Error fetching user: ", error)
    }

};
