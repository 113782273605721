import React, { useEffect } from 'react';
import Square from "./Square";
import SquareWithPiece from "./SquareWithPiece";
import config from '../../config';
import parseFEN from './parseFEN';

function Chessboard({ setClickedSquare, amICorrect, boardSize = 8, highlightRows=false, highlightCols=false, squaresToHighlight=[], displaysPieces=false, fen=null, displaysChecker=false }) {
    const squares = [];
    const letters = 'abcdefghijklmnopqrstuvwxyz'.slice(0, boardSize).split('');
    const board = parseFEN(fen);

    for (let row = 0; row < boardSize; row++) {
        for (let col = 0; col < boardSize; col++) {
            const isBlack = boardSize%2==0? (row + col) % 2 === 1 : (row + col) % 2 === 0;
            if (displaysPieces) {
                const piece = board[row][col];
                squares.push(
                    <SquareWithPiece
                        key={`${row}-${col}`}
                        isBlack={isBlack}
                        amICorrect={amICorrect}
                        position={`${letters[col]}${boardSize - row}`}
                        setClickedSquare={setClickedSquare}
                        boardSize={boardSize}
                        highlight={squaresToHighlight.includes(`${letters[col]}${boardSize - row}`)}
                        piece={piece ?
                            displaysChecker ?
                            (piece === piece.toUpperCase() ? config.chessPieces['wchecker'] : config.chessPieces['bchecker'])
                            :
                            (piece === piece.toUpperCase() ? config.chessPieces[`w${piece.toLowerCase()}`] : config.chessPieces[`b${piece}`])
                            :
                            null}
                    />
                );
            } else {
                squares.push(
                    <Square
                        key={`${row}-${col}`}
                        isBlack={isBlack}
                        position={`${letters[col]}${boardSize - row}`}
                        setClickedSquare={setClickedSquare}
                        amICorrect={amICorrect}
                        boardSize={boardSize}
                        highlight={squaresToHighlight.includes(`${letters[col]}${boardSize - row}`)}
                    />
                );
            }
        }
    }

    return (
        <div style={styles.boardContainer}>
            <div style={styles.mainContainer}>
                <div style={styles.leftRanks(boardSize)}>
                    {Array.from({ length: boardSize }, (_, i) => boardSize - i).map(rank => (
                        <div key={rank} style={styles.rankLabel(boardSize, highlightCols)}>{rank}</div>
                    ))}
                </div>
                <div style={styles.container(boardSize)}>
                    {squares}
                </div>
            </div>
            <div style={styles.bottomFiles(boardSize)}>
                {letters.map(letter => <div key={letter} style={styles.fileLabel(boardSize, highlightRows)}>{letter}</div>)}
            </div>
        </div>
    );
}

const styles = {
    boardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        color: 'white',
    },
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: (boardSize) => ({
        display: 'flex',
        flexWrap: 'wrap',
        width: `${boardSize * 5}vw`,
        height: `${boardSize * 5}vw`,
        borderRadius: '20px',
        overflow: 'hidden',
        position: 'relative',
    }),
    bottomFiles: (boardSize) => ({
        display: 'flex',
        paddingLeft: '2vmin',
        marginTop: '-2vmin',
        justifyContent: 'center',
        width: '100%',
        borderColor: 'white',
    }),
    fileLabel: (boardSize, highlightRows) => ({
        width: "5vw",
        textAlign: 'center',
        fontSize: highlightRows ? '2em' : '1.5em',
        fontWeight: 'bold',
        color: highlightRows ? 'green' : 'white',
    }),
    leftRanks: (boardSize) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingRight: '1vmin',
        paddingTop: '4vmin',
    }),
    rankLabel: (boardSize, highlightCols) => ({
        height: "5vw",
        textAlign: 'center',
        fontSize: highlightCols ? '2em' : '1.5em',
        fontWeight: 'bold',
        color: highlightCols ? 'green' : 'white',
    }),
};

export default Chessboard;