/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:da677ecb-3a2d-485e-adca-8f155b759d1b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nLxTiawX0",
    "aws_user_pools_web_client_id": "5mbip7v6lma9bi9soegogjg0rc",
    "oauth": {
        "domain": "sixtyfourd09731b5-d09731b5-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/home/,http://localhost:3000/home/,https://www.sixtyfourchess.com/,https://www.sixtyfourchess.com/home/",
        "redirectSignOut": "http://localhost:3000/,https://www.sixtyfourchess.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://f5ctdgija5bprjkoktk7tehecq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cgcstcrxencyxeibecwa4jg4c4"
};


export default awsmobile;
