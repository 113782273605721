import WChecker from './assets/chess_pieces/wchecker.svg';
import BChecker from './assets/chess_pieces/bchecker.svg';

export default {
    chessPieces: {
        "wp": require("./assets/chess_pieces/wp.png"),
        "wr": require("./assets/chess_pieces/wr.png"),
        "wn": require("./assets/chess_pieces/wn.png"),
        "wb": require("./assets/chess_pieces/wb.png"),
        "wq": require("./assets/chess_pieces/wq.png"),
        "wk": require("./assets/chess_pieces/wk.png"),
        "bp": require("./assets/chess_pieces/bp.png"),
        "br": require("./assets/chess_pieces/br.png"),
        "bn": require("./assets/chess_pieces/bn.png"),
        "bb": require("./assets/chess_pieces/bb.png"),
        "bq": require("./assets/chess_pieces/bq.png"),
        "bk": require("./assets/chess_pieces/bk.png"),
        "wchecker": WChecker,
        "bchecker": BChecker,
    },
}