import { useCallback, useEffect, useState } from "react";
import Chessboard from "../components/chessboard/Chessboard";
import useSound from 'use-sound';
import correctSound from '../assets/sounds/correct.wav';
import wrongSound from '../assets/sounds/wrong.wav';

import { fetchBlindfoldPuzzle } from "../api/fetchPuzzle";
import config from '../config';

function BlindfoldPuzzles() {
    const [clickedSquare, setClickedSquare] = useState(null);
    const [puzzle, setPuzzle] = useState(null);
    const [elo, setElo] = useState(1200);

    const [playCorrect] = useSound(correctSound, { volume: 0.1 });
    const [playWrong] = useSound(wrongSound, { volume: 0.1 });

    useEffect(() => {
        fetchBlindfoldPuzzle(elo, setPuzzle);
    }, [])

    const ControlPanel = useCallback(() => {
        if (!puzzle) return (
            <div style={styles.controlPanel}>
            </div>
        )
        return (
            <div style={styles.controlPanel}>
                 <h1 className="mt-10" style={{color: "white", fontSize: "3vw", fontWeight: '600'}}>
                    {puzzle.rating}
                </h1>

                <div className="mt-auto">
                    <div className="flex flex-col gap-2 items-center">
                        {puzzle && puzzle.themes.split(" ").map((theme, index) => {
                            return (
                                <div class="badge badge-primary">{theme}</div>
                            )}
                        )}
                    </div>
                    <div className="flex flex-col gap-2 items-center pt-4">
                        {puzzle && puzzle.openingTags.split(" ").map((openingTag, index) => {
                            return (
                                <div class="badge badge-secondary">{openingTag.replaceAll("_", ' ')}</div>
                            )}
                        )}
                    </div>
                </div>
                
            </div>
        )
    }, [puzzle]);
      
      

    return (
        <div style={styles.container}>
            <div style={styles.chessboardHolder}>
                <Chessboard
                setClickedSquare={setClickedSquare}
                amICorrect={() => {}}
                boardSize={8}
                displaysPieces={puzzle? true : false}
                fen={puzzle && puzzle.fen}
                />
            </div>
            <ControlPanel />
        </div>


    );
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        fontFamily: "Raleway, sans-serif",
        height: '100%',
        width: '100%',
    },
    chessboardHolder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    controlPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '5px',
        height: '80vh',
        width: '20vw',
        backgroundColor: '#242424',
        padding: '20px',
    },
}

export default BlindfoldPuzzles;