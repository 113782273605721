import { OPENING_PUZZLE_HANDLER } from "../Endpoints";

export const getCompleted = async(userId, setCompleted) => {
    console.log("[API Request] - getCompleted")
    try {

        const response = await fetch(OPENING_PUZZLE_HANDLER, {
            method: 'POST',
            body: JSON.stringify({
                userId: userId,
                queryType: "getCompleted"
            })
        })

        if (!response.ok) {
            throw new Error("Network response was not ok.");
        }

        const data = await response.json();
        setCompleted(JSON.parse(data['body']))
        return data['body']

    } catch (error) {
        console.log(error)
    }
}