import React, { useState, useEffect } from "react";
import useSound from 'use-sound';
import Chessboard from "../../components/chessboard/Chessboard";
import correctSound from '../../assets/sounds/correct.wav';
import wrongSound from '../../assets/sounds/wrong.wav';
import VisionLogo from '../../assets/VisionLogo.png';

import { useSpring, animated, easings } from '@react-spring/web';

import { useNavigate } from "react-router-dom";

function Demo() {
  const navigate = useNavigate();
  const [playCorrect] = useSound(correctSound, { volume: 0.1 });
  const [playWrong] = useSound(wrongSound, { volume: 0.1 });
  const [squaresToClick, setSquaresToClick] = useState(['a1', 'a2', 'b1', 'b2']);
  const messages = [
    "Welcome to the first step of the demo!",
    "We are starting with a 2x2 chessboard\nThis is the very bottom left corner of the board",
    "The numbers going down the board are the rank",
    "The letters going across the board are the file",
    "A square is defined by a rank and a file\nFor example, this square is: a1",
    "Good luck!",
    ""
  ];

  const [chatBubbleText, setChatBubbleText] = useState(messages[0]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [clickToContinue, setClickToContinue] = useState(false);
  const [highlightRows, setHighlightRows] = useState(false);
  const [highlightCols, setHighlightCols] = useState(false);
  const [squaresToHighlight, setSquaresToHighlight] = useState([]);
  const [beginGame, setBeginGame] = useState(false);
  const [clickedSquare, setClickedSquare] = useState(null);
  const [showToast, setShowToast] = useState(false);



  useEffect(() => {
    // Handles delaying the clickToContinue option
    if (clickToContinue == false) {
      setTimeout(() => {
        setClickToContinue(true);
      }, 2000);
    }
  }, [clickToContinue]);

  useEffect(() => {
    if (clickedSquare && beginGame) {
      if (clickedSquare == squaresToClick[0]) {
        playCorrect();
        setSquaresToClick(squaresToClick.slice(1));
        setSquaresToHighlight([...squaresToHighlight, clickedSquare]);
        if (clickedSquare == "b2") {
          setShowToast(true);
        }
      } else {
        playWrong();
      }
    }
    setClickedSquare(null);
  }, [clickedSquare]);

  const amICorrect = ({ position }) => {
    return squaresToClick[0] === position;
  }

  const handleNextMessage = () => {
    if (currentMessageIndex < messages.length - 1 && clickToContinue) {
      if (currentMessageIndex+1 === 2) {
        setHighlightCols(true);
      }
      if (currentMessageIndex+1 === 3) {
        setHighlightCols(false);
        setHighlightRows(true);
      }
      if (currentMessageIndex+1 === 4) {
        setHighlightRows(false);
        setSquaresToHighlight(["a1"]);
      }
      if (currentMessageIndex+1 === 5) {
        setSquaresToHighlight([]);
      }
      if (currentMessageIndex+1 === 6) {
        console.log("Beginning the game!")
        setBeginGame(true);
      }

      setChatBubbleText(messages[currentMessageIndex + 1]);
      setCurrentMessageIndex(currentMessageIndex + 1);
      setClickToContinue(false);
    }
  };
  const TimeLine = () => {
    return (
      <ul className="timeline timeline-vertical">
      <li>
          <div className="timeline-middle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-success"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end timeline-box text-success">Start the Demo</div>
          <hr/>
      </li>
      <li>
          <hr/>
          <div className="timeline-middle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end timeline-box">Four Squares</div>
          <hr/>
      </li>
      <li>
          <hr/>
          <div className="timeline-middle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-gray-600"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end timeline-box text-gray-600">?</div>
      </li>
      <li>
          <hr/>
          <div className="timeline-middle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-gray-600"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end timeline-box text-gray-600">?</div>
      </li>
      <li>
          <hr/>
          <div className="timeline-middle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-gray-600"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
          </div>
          <div className="timeline-end timeline-box text-gray-600">?</div>
      </li>
      </ul>
  )
  }

  const formattedText = chatBubbleText.split('\n').map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));

  const navigateHome = () => {
    window.location.href = '/';
  }

  const Toast = ({ show, message }) => {
    console.log("clickedSquare: ", clickedSquare)
    console.log("Show: ", show)
    const props = useSpring({
      from: { opacity: 0, transform: 'translateY(20px)' },
      to: { opacity: show ? 1 : 0, transform: show ? 'translateY(0)' : 'translateY(20px)' },
      config: { duration: 500 },
    });
  
    return (
      <animated.div style={{ ...styles.successToast, ...props }} role="alert" className="alert alert-success">
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>{message}</span>
      </animated.div>
    );
  };

  const navigatePhaseTwo = () => {
    console.log("Navigating to phase two")
    window.location.href = '/demo2';
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen overflow-hidden" onClick={handleNextMessage}>
      {beginGame && (<img src={VisionLogo} alt="Vision Logo" style={styles.visionLogo} onClick={navigateHome} className="w-32 h-32"/>)}
      {beginGame && squaresToClick.length == 0 && <button onClick={navigatePhaseTwo} className="absolute right-10 w-2/12 btn btn-outline btn-secondary">Begin Phase Two</button>}
      <div className="flex flex-row items-center justify-center w-full pt-12">
      {!beginGame && 
      <>
        <img src={VisionLogo} alt="Vision Logo" className="w-40 h-40"/>
          <div className="chat chat-start">
            <div className="chat-bubble grow max-w-lg">
              <p className="text-warning">{formattedText}</p>
              {!clickToContinue && (
                <>
                  <br /><p className="text-xs font-light text-slate-50 opacity-0">Click anywhere to continue</p>
                </>
              )}
              {clickToContinue && (
                <>
                  <br />
                  <p className="text-xs font-light text-slate-50">Click anywhere to continue</p>
                </>
              )}
            </div>
          </div>
          </>
        }
        {beginGame && <div>
          <h1 className="text-3xl text-center text-primary">
            {squaresToClick.length > 0 ? <>Click on the <span className=" text-green-400">{squaresToClick[0]}</span> square</> : <>Congratulations! <br></br> You can now go to the next phase</>}
          </h1>
          </div>}
      </div>
      <div className="m-auto" style={styles.chessBoardTranslation}>
        {currentMessageIndex != 0 && <Chessboard 
          setClickedSquare={setClickedSquare}
          amICorrect={amICorrect}
          boardSize={2} 
          highlightRows={highlightRows}
          highlightCols={highlightCols}
          squaresToHighlight={squaresToHighlight}
        />}
      </div>
      <div className="absolute -left-32">
        <TimeLine/>
      </div>
      <Toast show={showToast} message="Phase One Completed!" />
      <button onClick={() => navigate('/home')} className="absolute bottom-5 left-5 btn btn-secondary">Skip Demo</button>
    </div>
  )
}

const styles = {
  chessBoardTranslation: {
    transform: 'translate(-5%)',
  },
  visionLogo: {
    position: 'absolute',
    top: '0',
    left: '0',
    cursor: 'pointer',
  },
  successToast: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    width: '20%',
    padding: '1rem',
    zIndex: '100',
    textAlign: 'center',
  }
}

export default Demo;