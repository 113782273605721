import { useEffect, useState } from "react";

import Chessboard from "../components/chessboard/Chessboard";
import { analyzeGame } from "../api/analyzeGame";

import useSound from 'use-sound';
import correctSound from '../assets/sounds/correct.wav';
import wrongSound from '../assets/sounds/wrong.wav';


function Vision({ userData }) {
    const gameLength = 30;

    const [randomSquare, setRandomSquare] = useState(null);
    const [clickedSquare, setClickedSquare] = useState(null);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [timeLeft, setTimeLeft] = useState(gameLength);
    const [gameResults, setGameResults] = useState([]);
    const [score, setScore] = useState(0);
    const [actionStartTime, setActionStartTime] = useState(performance.now());
    const [accuracy, setAccuracy] = useState(0);
    const [speed, setSpeed] = useState(5);
    const [totalClicks, setTotalClicks] = useState(0);

    const [playCorrect] = useSound(correctSound, { volume: 0.1 });
    const [playWrong] = useSound(wrongSound, { volume: 0.1 });


    useEffect(() => {
        // Handles logic for the timer
        let timer;
        if (isGameStarted && timeLeft > 0) {
            timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            if (isGameStarted){
                endGame();
            }
            
        }
        return () => clearTimeout(timer);
    }, [isGameStarted, timeLeft]);

    useEffect(() => {
        if (isGameStarted) {
            // Handles logic for when a square is clicked and game is started
            const elapsedTime = performance.now() - actionStartTime;
            setTotalClicks(totalClicks + 1);
            setGameResults([...gameResults, {"clicked": clickedSquare, "goal": randomSquare, "time": Math.round(elapsedTime)}]);
            if (clickedSquare === randomSquare) {
                console.log("New Accuracy:", (score+1) / (totalClicks+1));
                console.log("New Speed:", (performance.now() - actionStartTime)/(1000*score+1));
                setAccuracy((score+1) / (totalClicks+1));
                setSpeed((performance.now() - actionStartTime)/(1000*(score+1)));

                playCorrect();
                setRandomSquare(generateRandomSquare());
                setScore(score + 1);

            } else {
                playWrong();
            }
        }
    }, [clickedSquare]);


    const generateRandomSquare = () => {
        // Function for generating the next random square
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
        const randomLetter = letters[Math.floor(Math.random() * letters.length)];
        const randomNumber = Math.floor(Math.random() * 8) + 1;
        if (randomSquare == `${randomLetter}${randomNumber}`) {
            return generateRandomSquare();
        }
        return `${randomLetter}${randomNumber}`;
    }

    const amICorrect = ({position}) => {
        // Function passed down to the Square so it can know if it was the right one on click
        if (isGameStarted) {
            return position === randomSquare;
        } else {
            return null;
        }
        
    }

    const startGame = () => {
        // Game starts logic

        setScore(0);
        setTotalClicks(0);
        setSpeed(5);
        setAccuracy(0);


        setIsGameStarted(true);
        setRandomSquare(generateRandomSquare());
        setTimeLeft(gameLength);

        setGameResults([]);
        setActionStartTime(performance.now());
    }

    const endGame = () => {
        setIsGameStarted(false);
        setRandomSquare(null);
        analyzeGame(gameResults, userData.id)
        console.log("Game Results: ", gameResults);
    }
    

    const ControlPanel = () => {
        let timeLeftColor = () => {
            if (timeLeft <= 10) {
                return 'error';
            } else if (timeLeft <= 20) {
                return 'warning';
            } else {
                return 'success';
            }
        }
        return (
            <div style={styles.controlPanel}>
            <div style={styles.stats}>
            <div className={`radial-progress text-${timeLeftColor()} mt-auto`}
            style={{ "--value": `${timeLeft*(100/gameLength)}`,
            "--size": "7rem", "--thickness": "1rem" }} role="progressbar">
                <p className="text-xl font-semibold">{timeLeft}s</p></div>
                <h3 className="mt-10" style={styles.score}>{score}</h3>
            </div>
            <div className="mt-auto w-full">
                <div>
                    <div className="flex flex-row w-full justify-between"><p>Accuracy</p><p className="text-lg">{Math.round(accuracy*100)}%</p></div>
                    <progress
                        className={`progress progress-success w-11/12 mt-2`}
                        value={accuracy}
                        max="1"
                    />
                </div>
                <div>
                    <div className="flex flex-row justify-between"><p>Speed</p><p className="text-lg">{speed==5? 0 : Math.round(speed*100)/100}s</p></div>
                    <progress
                        className={`progress progress-success w-11/12 mt-2`}
                        value={5-speed}
                        max="5"
                    />
                </div>
            </div>
            { !isGameStarted &&
            <button className="btn btn-secondary w-11/12 mt-auto" onClick={startGame}><p className="text-stone-100 text-lg">Start</p></button>
            }
        </div>
        )
    }
    
      
      

    return (
        <div style={styles.container}>
            <div style={styles.chessboardHolder}>
                <h3 style={styles.randomSquare}>{randomSquare}</h3>
                <Chessboard
                setClickedSquare={setClickedSquare}
                amICorrect={amICorrect}
                boardSize={8}
                />
            </div>
            <ControlPanel />
            
        </div>


    );
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        fontFamily: "Raleway, sans-serif",
        height: '100%',
        width: '100%',
    },
    logoImage: {
        position: 'absolute',
        cursor: 'pointer',
        top: '10px',
        left: '10px',
        width: '100px',
        height: '100px',
        zIndex: 1,
    },
    title: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        color: 'white',
        fontSize: '3em',
        textAlign: 'center',
        marginTop: '20px',
    },
    chessboardHolder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    randomSquare: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        fontSize: '3.5em',
        opacity: 0.8,
        textAlign: 'center',
        pointerEvents: 'none',
        zIndex: 10,
    },
    controlPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '5px',
        height: '80vh',
        width: '20vw',
        backgroundColor: '#242424',
        padding: '20px',
    },
    stats: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '30vmin',
    },
    timer: {
        color: 'white',
        fontSize: '3em',
    },
    score: {
        color: 'white',
        fontSize: '6vw',
    },
}

export default Vision;