import { FETCH_USER_STATS } from "../Endpoints";

export const fetchUserStats = async(id, setUserStats) => {
    console.log("[API Request] - fetchUserStats")
    try {
        const response = await fetch(FETCH_USER_STATS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
            }),
        });
        const data = await response.json();
        console.log("User stats: ", data['body'])
        setUserStats(data['body'])
    } catch (error) {
        console.log("Error fetching user: ", error)
    }

};
