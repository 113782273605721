import { useState, useEffect } from 'react';

import useSound from 'use-sound';
import correctSound from '../assets/sounds/correct.wav';
import wrongSound from '../assets/sounds/wrong.wav';

import FullHeart from '../assets/full_heart.png';
import EmptyHeart from '../assets/empty_heart.png';
import { processColorGuesser } from '../api/processColorGuesser';


export default function ColorGuesser({ userData }) {
    const [randomSquare, setRandomSquare] = useState();
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [score, setScore] = useState(0);
    const [lastCorrect, setLastCorrect] = useState(false);
    const [timeLeft, setTimeLeft] = useState(100);
    const [lives, setLives] = useState(5);
    const [gameResults, setGameResults] = useState([]);

    const [playCorrect] = useSound(correctSound, { volume: 0.1 });
    const [playWrong] = useSound(wrongSound, { volume: 0.1 });

    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

    const generateRandomSquare = () => {
        const randomLetter = letters[Math.floor(Math.random() * letters.length)];
        const randomNumber = Math.floor(Math.random() * 8) + 1;
        if (randomSquare == `${randomLetter}${randomNumber}`) {
            return generateRandomSquare();
        }
        return `${randomLetter}${randomNumber}`;
    }

    const guess = (color) => {
        if (!isGameStarted) return;

        let randomSquareColor = (letters.indexOf(randomSquare[0]) + 1 + parseInt(randomSquare[1])) % 2 == 0 ? 'black' : 'white';
        setRandomSquare(generateRandomSquare());
        setGameResults([...gameResults, {"clicked": color, "goal": randomSquare, "correct": randomSquareColor}]);
        if (randomSquareColor === color) {
            playCorrect();
            setScore(score + 1);
            setLastCorrect(true);
        } else {
            playWrong();
            setLastCorrect(false);
            setLives(lives - 1);
        }
        setTimeLeft(100);
    }

    useEffect(() => {
        if (lives <= 0 && isGameStarted) {
            endGame();
        }
    }, [lives])

    const endGame = () => {
        if (isGameStarted) {
            processColorGuesser(gameResults, userData.id)
            setIsGameStarted(false);
            setGameResults([]);
        }
    }

    const startGame = () => {
        setRandomSquare(generateRandomSquare());
        setIsGameStarted(true);
        setScore(0);
        setTimeLeft(100);
        setLives(5);
    }

    useEffect(() => {
        if (!isGameStarted) return;

        const interval = setInterval(() => {
            setTimeLeft(prevTimeLeft => {
                if (prevTimeLeft <= 0) {
                    clearInterval(interval);
                    setLives(0);
                    return 0;
                }
                return prevTimeLeft - (0.1 + score / 100);
            });
        }, 10);

        return () => clearInterval(interval);
    }, [isGameStarted, score]);

    return (
        <div className='h-full w-full'>
            <h1
            className='text-center text-4xl font-bold'
            style={{paddingTop: '6vmax'}}>{isGameStarted? score : `Color Guesser`}</h1>

            {randomSquare &&
            <h1 className='text-center text-5xl font-bold' style={{paddingTop: '8vmin', color: lastCorrect? 'green' : 'red'}}>
                {randomSquare}
            </h1>
            }

            <div className='absolute w-full top-1/2 justify-center flex flex-row' style={{columnGap: '10vmin'}}>
                <button style={{height: '10vmin', minHeight: 65, width: '10vmin', minWidth: 65}} onClick={() => guess("black")} className='btn btn-primary'></button>
                <button style={{height: '10vmin', minHeight: 65, width: '10vmin', minWidth: 65}} onClick={() => guess("white")} className='btn bg-white'></button>
            </div>

            {isGameStarted?
            (
                <div className='absolute w-full flex flex-col justify-center items-center left-0' style={{bottom: '20vmin'}}>
                    <progress className="progress progress-success w-72" value={timeLeft} max="100"></progress>
                    <div className='flex-row justify-items-start flex pt-4'>
                    {
                        Array.from({length: 5}, (_, i) => (
                            i < lives?
                            <img key={i} src={FullHeart} style={{height: '5vmin', width: '5vmin', minWidth: 30, minHeight: 30}}/>
                            :
                            <img key={i} src={EmptyHeart} style={{height: '5vmin', width: '5vmin', minWidth: 30, minHeight: 30}}/>
                        ))
                        
                    }
                    </div>
                </div>
            )            
            :
            (
                <div className='absolute w-full flex justify-center items-center bottom-8'>
                    <button onClick={() => startGame()} className='btn btn-primary'>Start Game</button>
                </div>
            )
            }
        </div>
    )
}