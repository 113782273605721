import { useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from 'aws-amplify/auth';
import { updateUserInfo } from '../api/profile/updateUserInfo';
import AWS from 'aws-sdk';

import GoldBadge from '../assets/badges/gold-badge-no-bg.png';
import SilverBadge from '../assets/badges/silver-badge-no-bg.png';
import BronzeBadge from '../assets/badges/bronze-badge-no-bg.png';

const S3_BUCKET ='sixtyfour-public-assets';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})


  function Profile({ userData, userStats, setRefreshTrigger }) {
    const [editMode, setEditMode] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);


    const handleSignout = async () => {
        try {
            await signOut();
            window.location.href = '/';
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    
    const handleFileChange = async (event) => {
        setUploadedFile(event.target.files[0]);
      };

    const handleEditProfile = async () => {
        let reloadData = false
        const newUsername = document.getElementById('profileNameChange').value;
        if (newUsername.length >= 3 && newUsername.length <= 20 && newUsername != userData.profileName) {
            updateUserInfo({ userId: userData.id, field: 'profileName', value: newUsername});
            reloadData = true;
        }
        if (uploadedFile) {
                const newUrl = `profilepictures/${userData.id}.jpg`;
                const params = {
                    ContentType: uploadedFile.type,
                    ACL: 'public-read',
                    Body: uploadedFile,
                    Bucket: S3_BUCKET,
                    Key: newUrl,
                };
                myBucket.putObject(params)
                    .send((err) => {
                        if (err) console.log(err)
                    })
            reloadData = true;
            }
        if (reloadData) setRefreshTrigger((prev) => prev + 1);
        setEditMode(false);
        }
    
    

    return (
        <div className='w-full h-full'>
            <div className='flex flex-col jusify-center align-center items-center'>
                {/* Profile Picture */}
                <div className="avatar pt-14">
                    <div className="w-24 rounded-xl">
                        <img src={userData.picture} />
                    </div>
                </div>

               {editMode && (
                <div className='pt-4'>
                    <input type="file" onChange={handleFileChange} className="file-input file-input-bordered file-input-sm w-full max-w-xs" />
                </div>
               )}

                <div className='flex flex-col mt-12'>
                   {editMode? 
                   (
                    <div className='flex flex-col items-center justify-center'>
                        <div className='flex flex-row justify-start items-center pt-4'>
                            <p className='pr-4 text-xl'>Username:</p>
                            <input
                            id="profileNameChange"
                            type="text"
                            placeholder={userData.profileName}
                            className="input input-bordered input-sm w-full max-w-md text-primary"
                            />
                            
                        </div>
                        <button onClick={handleEditProfile} className='btn btn-success btn-sm btn-wide mt-12'>Save</button>
                    </div>
                   )
                   :
                   (
                    <div className='flex items-center'>
                        {/* Username */}
                    <p className='text-primary text-2xl'>{userData.profileName != "" ? userData.profileName : "Add a username!"}</p> 
                        {userData.profileName === "" &&
                            <button className='btn btn-primary btn-sm ml-4' onClick={() => setEditMode(true)}>Edit</button>
                        }
                    </div>
                   )
                }

                </div>
                {!editMode &&
                <div className='flex flex-col justify-evenly items-center py-4 px-8 bg-base-300 rounded-2xl shadow-base-200 shadow-xl ' style={{width: '40vw', height: '20vw', minHeight: 350,  minWidth: 440, marginTop: '6vh'}}>
                    {/* Vision Stats */}
                    <div>
                        <h1 style={{ fontSize: 'clamp( 1.2rem, 1.5vmax, 2rem)' }} className='text-center text-primary font-bold pb-3'>Vision</h1>
                        <div className="stats shadow w-full">
                            <div className="stat">
                                <div style={{ fontSize: 'clamp(0.9rem, 0.8vmax,  1.2rem)' }} className="stat-title">Games Played</div>
                                <div style={{ fontSize: 'clamp( 1.2rem, 1.5vmax, 2rem)' }} className="stat-value text-primary">{userStats.visionGamesPlayed}</div>
                            </div>

                            <div className="stat">
                                <div style={{ fontSize: 'clamp(0.9rem, 0.8vmax,  1.2rem)' }} className="stat-title">Squares Clicked</div>
                                <div style={{ fontSize: 'clamp(1.2rem, 1.5vmax, 2rem)' }} className="stat-value text-primary">{userStats.totalSquaresClicked}</div>
                            </div>

                            <div className="stat">
                                <div style={{ fontSize: 'clamp(1.2rem, 1.5vmax, 2rem)' }} className="stat-value text-center text-primary">{userStats.visionHighScore}</div>
                                <div style={{ fontSize: 'clamp(0.9rem, 0.8vmax,  1.2rem)' }} className="stat-title">High Score</div>
                            </div>
                        </div>
                    </div>
                        {/* Puzzle Stats */}
                        <div>
                            <h1 style={{ fontSize: 'clamp( 1.2rem, 1.5vmax, 2rem)' }} className='text-center text-2xl text-secondary font-bold pb-3'>Color Guesser</h1>
                            <div className="stats shadow w-full">
                                <div className="stat">
                                    <div style={{ fontSize: 'clamp(0.9rem, 0.8vmax,  1.2rem)' }} className="stat-title"># Completed</div>
                                    <div style={{ fontSize: 'clamp( 1.2rem, 1.5vmax, 2rem)' }} className="stat-value text-secondary">{userStats.colorGuesserGamesPlayed}</div>
                                </div>

                                <div className="stat">
                                <div style={{ fontSize: 'clamp(1.2rem, 1.5vmax, 2rem)' }} className="stat-value text-center text-secondary">{userStats.colorGuesserHighScore}</div>
                                <div style={{ fontSize: 'clamp(0.9rem, 0.8vmax,  1.2rem)' }} className="stat-title">High Score</div>
                            </div>
                            </div>
                        </div>
                </div>}
            </div>

            <div className='absolute bottom-4 right-0'>
                <button className={`btn btn-${editMode? 'warning':'success'} btn-sm mr-4`} onClick={() => setEditMode(!editMode)}>{editMode? "Cancel" : "Edit"}</button>
                <button className='btn btn-error btn-sm' onClick={handleSignout}>Sign Out</button>
            </div>
        </div>
    );
}

export default withAuthenticator(Profile);