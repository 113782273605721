import { useState } from 'react';
import GoldBadge from '../assets/badges/gold-badge-no-bg.png';
import SilverBadge from '../assets/badges/silver-badge-no-bg.png';
import BronzeBadge from '../assets/badges/bronze-badge-no-bg.png';


function Leaderboard ({ visionLeaderboard }) {
    const [leaderBoardRows, setLeaderBoardRows] = useState(visionLeaderboard);

    const renderLeaderBoardRow = (row, index) => {
        return (
            <tr className=''>
                <th>
                    {index == 0 && <img src={GoldBadge} alt="Gold Badge" style={styles.leaderboardBadge}/>}
                    {index == 1 && <img src={SilverBadge} alt="Silver Badge" style={styles.leaderboardBadge}/>}
                    {index == 2 && <img src={BronzeBadge} alt="Bronze Badge" style={styles.leaderboardBadge}/>}
                    {index > 2 && <p className='pl-4 text-lg'>{index + 1}</p>}
                </th>
                <td>
                    <div className="flex items-center gap-3">
                        <div className="avatar">
                            <div className="mask mask-squircle" style={{height:'5vh', width: '5vh', minWidth: 30, minHeight: 30}}>
                                <img
                                    src={row.picture}
                                    alt="Avatar Tailwind CSS Component" />
                            </div>
                        </div>
                        <div>
                            <div className="font-bold">{row.profileName}</div>
                        </div>
                    </div>
                </td>
                <td>{row.score}</td>
                <td>{row.accuracy*100}%</td>
                <td>{row.avgTime}s</td>
                <th>
                    <p>{row.createdAt.split("T")[0]}</p>
                </th>
            </tr>
        )
    }

    return (
        <div className='absolute w-full flex flex-col items-center' style={{paddingRight: '6vw'}}>
            <h1 className='text-3xl font-bold' style={{marginTop: '5vh'}}>Leaderboard</h1>
            <div className="overflow-x-auto" style={{marginTop: '5vh'}}>
                <table className="table">
                    {/* head */}
                        <thead>
                        <tr>
                            <th>
                                <p></p>
                            </th>
                            <th>User</th>
                            <th>Score</th>
                            <th>Accuracy</th>
                            <th>Speed</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {leaderBoardRows.map((row, index) => renderLeaderBoardRow(row, index))}
                        </tbody>
                    </table>
                </div>
        </div>
    )
}

const styles = {
    leaderboardBadge: {height:'6vh', width: '5.5vh', minWidth: 23, minHeight: 25}
}

export default Leaderboard;