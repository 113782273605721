import React, { useState } from 'react';

import VisionLogo from '../assets/VisionLogo.png';

function goHome() {
    window.location.href = '/home';
}


export default function SideBar({ activePage, setActivePage }) {
    return (
    <div style={styles.container}>
        <ul className="menu bg-base-200 w-full h-full">
            <img src={VisionLogo} alt="Vision Logo" style={styles.logoImage} onClick={goHome}/>
            <div className="divider"></div>


            <li className='w-11/12'>
                <a onClick={() => setActivePage("vision")}><p className={activePage == 'vision'? 'text-primary' : 'white'}>Vision</p></a>
            </li>
            <li className='w-11/12'>
                <a onClick={() => setActivePage("color guesser")}><p className={activePage == 'color guesser'? 'text-primary' : 'white'}>Color Guesser</p></a>
            </li>
            <li className='w-11/12'>
                <a onClick={() => setActivePage("blindfold puzzles")}><p className={activePage == 'blindfold puzzles'? 'text-primary' : 'white'}>Blindfold Puzzles</p></a>
            </li>
            <div className='divider'></div>
            <li className='w-11/12'>
                <a onClick={() => setActivePage("leaderboard")}><p className={activePage == 'leaderboard'? 'text-primary' : 'white'}>Leaderboard</p></a>
            </li>
            <li className='w-11/12'>
                <a onClick={() => setActivePage("profile")}><p className={activePage == 'profile'? 'text-primary' : 'white'}>My Profile</p></a>
            </li>
            <div className='divider'></div>
            <li className='w-11/12'>
                <a onClick={() => {window.location.href = "/demo"}}><p className={activePage == 'demo'? 'text-primary' : 'white'}>Demo</p></a>
            </li>
        </ul>
    </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'start',
        height: '100vh',
        width: '12.5vw',
        maxWidth: 200,
        minWidth: 120,
    },
    logoImage: {
        width: '8vw',
        cursor: 'pointer',
        maxWidth: 100,
        minWidth: 80
    }
}