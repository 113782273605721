import React, { useState } from 'react';

function Square({ isBlack, position, setClickedSquare, amICorrect, boardSize, highlight=false }) {
    const [correct, setCorrect] = useState(null);

    const handleClick = () => {
        setClickedSquare(position);
        const isCorrect = amICorrect({ position });
        setCorrect(isCorrect);

        setTimeout(() => {
            setCorrect(null);
        }, 150);
    };

    return (
        <div
            style={styles.square(isBlack, correct, boardSize, highlight)}
            onMouseDown={handleClick}
        >
            {/* <p style={{color: "black", fontWeight: "bold", fontSize: "1.5em"}}>{position}</p> */}
        </div>
    );
}

const styles = {
    square: (isBlack, correct, boardSize, highlight) => {
        const size = `${100 / boardSize}%`;
        return {
            width: size,
            height: size,
            backgroundColor: highlight ? 'rgba(20, 255, 20, 0.5)' : isBlack ? '#E87900' : 'white',
            boxShadow: correct === null ? 'none' : correct ? '0px 0px 0px 5px #3ac24a inset' : '0px 0px 0px 5px #ff4747 inset',
            zIndex: correct === null ? 'auto' : 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
    },
};

export default Square;
