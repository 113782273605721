import { useCallback, useEffect, useState } from "react";
import useSound from 'use-sound';
import correctSound from '../assets/sounds/correct.wav';
import wrongSound from '../assets/sounds/wrong.wav';
import { fetchOpeningPuzzles } from "../api/fetchOpeningPuzzles";
import OpeningPuzzle from "./OpeningPuzzle";
import { useNavigate } from "react-router-dom";
import { markCompleted } from "../api/openingPuzzles/markCompleted";
import { getCompleted } from "../api/openingPuzzles/getCompleted";


function OpeningPuzzles({ userData }) {
    const navigate = useNavigate();
    const [clickedSquare, setClickedSquare] = useState(null);
    const [elo, setElo] = useState(1200);
    const [puzzles, setPuzzles] = useState(null);
    const [puzzleSelectionMode, setPuzzleSelectionMode] = useState(true);
    const [chosenPuzzle, setChosenPuzzle] = useState(null);
    const [completed, setCompleted] = useState([]);

    const [playCorrect] = useSound(correctSound, { volume: 0.1 });
    const [playWrong] = useSound(wrongSound, { volume: 0.1 });
    


    useEffect(() => {
        fetchOpeningPuzzles(setPuzzles);
        getCompleted(userData.id, setCompleted)
    }, [])

    const selectPuzzle = (puzzle) => {
        setChosenPuzzle(puzzle);
        setPuzzleSelectionMode(false);
    }

    const puzzleCompleted = useCallback((puzzleId) => {
        console.log([...completed, puzzleId])
        const newCompleted = [...completed, puzzleId]
        markCompleted(userData.id, puzzleId)
        setCompleted(newCompleted)
        setPuzzleSelectionMode(true);
    }, [userData, completed])
      
    const RenderPuzzleCard = useCallback((puzzle) => {
        const isCompleted = completed.includes(puzzle.id);
        const baseColor =  isCompleted ? 'bg-gray-600' : 'bg-base-300';
        return (
            <button
            onClick={() => {selectPuzzle(puzzle)}}
            className={`bottom-4 w-72 h-36 right-4 btn btn-sm ${baseColor} shadow-xl hover:shadow-secondary`}
            >
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">{puzzle.Opening}</h2>
                        
                        <p className="text-primary">{`Rating: ` + puzzle.Rating}</p>
                        <div className="card-actions justify-center">
                        {isCompleted &&<div className="badge badge-success">Completed</div>}
                        </div>
                    </div>
                </div>
            </button>
        )
    }, [completed]);

    const RenderOpeningPuzzle = useCallback(() => {
        if (!chosenPuzzle) return (
            <div style={styles.controlPanel}>
            </div>
        )
        return (
            <OpeningPuzzle puzzle={chosenPuzzle} puzzleCompleted={puzzleCompleted} setPuzzleSelectionMode={setPuzzleSelectionMode}/>
        )
    }, [chosenPuzzle]);

    return (
        puzzleSelectionMode ?
        (
        <div style={styles.container}>
            <h1 className="text-center text-4xl font-bold text-primary" style={{marginTop: '6vh', paddingBottom: '6vh'}}>Opening Puzzles</h1>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '2vh',
                    position: 'relative',
                }}
            >
                {puzzles && puzzles.map(puzzle => (
                    <RenderPuzzleCard key={puzzle.id} {...puzzle} />
                ))}
            </div>
        </div>
        )
        :
        (
            <RenderOpeningPuzzle />
        )
    );
}


const styles = {
    container: {
        fontFamily: "Raleway, sans-serif",
        height: '100%',
        width: '100%',
    },
}

export default OpeningPuzzles;