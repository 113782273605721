import { FETCH_VISION_LEADERBOARD } from "./Endpoints";

export const fetchVisionLeaderboard = async(setVisionLeaderboard) => {
    console.log("[API Request] - fetchVisionLeaderboard")
    try {
        const response = await fetch(FETCH_VISION_LEADERBOARD, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        });
        const data = await response.json();
        setVisionLeaderboard(data['body'])
    } catch (error) {
        console.log("Error fetching vision leaderboard: ", error)
    }
};
