import { PROCESS_COLOR_GUESSER } from "./Endpoints";

export const processColorGuesser = async(game, userId) => {
    console.log("[API Request] - processColorGuesser");
    const obj = {
        game: game,
        userId: userId
    };
    console.log("Game: ", obj);

    try {
        const response = await fetch(PROCESS_COLOR_GUESSER, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj)
        });

        if (!response.ok) {
            throw new Error("Network response was not ok.");
        }

        const data = await response.json();
    } catch (error) {
        console.log("Error analyzing game: ", error);
    }
};
