import { UPDATE_USER_INFO } from "../Endpoints";

export const updateUserInfo = async({userId, field, value}) => {
    console.log("[API Request] - fetchUser")
    try {
        const response = await fetch(UPDATE_USER_INFO, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                field: field,
                value: value
            }),
        });
        const data = await response.json();
    } catch (error) {
        console.log("Error fetching user: ", error)
    }

};